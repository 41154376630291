import {Component,OnInit,OnDestroy,Renderer2,HostBinding} from '@angular/core';
//import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
//import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'login-box';
    public loginForm: UntypedFormGroup;
    public isAuthLoading = false;
    public isGoogleLoading = false;
    public isFacebookLoading = false;

    constructor(private renderer: Renderer2,private toastr: ToastrService,private appService: AppService
        , private spinner : NgxSpinnerService) {}

    ngOnInit() {
        this.renderer.addClass(document.querySelector('app-root'),'login-page');
        this.loginForm = new UntypedFormGroup({
            email: new UntypedFormControl(null, Validators.required),
            password: new UntypedFormControl(null, Validators.required)
        });
    }

    async loginByAuth() {
        
        if (this.loginForm.valid) {
            this.isAuthLoading = true;
            //this.spinner.show();
            await this.appService.loginByAuth(this.loginForm.value);
            //this.spinner.hide();
            this.isAuthLoading = false;
        } else {
            this.toastr.error('Complete el formulario');
        }
    }

    ngOnDestroy() {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'login-page'
        );
    }
}
